class BrowserService {
  get isBrowser() {
    const hasDocument = typeof document === 'object';
    const hasWindow = typeof window === 'object';

    return hasDocument && hasWindow;
  }

  get windowSize() {
    return {
      width: this.isBrowser ? window.innerWidth : 0,
      height: this.isBrowser ? window.innerHeight : 0,
    };
  }

  get devicePixelRatio() {
    return this.isBrowser ? window.devicePixelRatio : 1;
  }

  matchMedia(query) {
    return window.matchMedia(query);
  }
}

const instance = new BrowserService();
export { instance as BrowserService };
