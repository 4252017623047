import React, { useState } from 'react';
import { navigate } from 'gatsby';
import clsx from 'clsx';
import trackEvent from 'gatsby-plugin-simple-analytics';

const PrimaryButton = ({ className, ...props }) => (
  <div className="shadow rounded-lg transition-all duration-100 ease-out hover:opacity-90 hover:shadow-none active:opacity-100">
    <button
      className={clsx(
        className,
        'px-6 py-4 block w-full font-display font-bold text-lg leading-4 text-center no-underline text-white bg-yellow-500 rounded-lg shadow-inner-light transition-all duration-100 ease-out focus:outline-none focus:ring focus:ring-yellow-500 focus:ring-opacity-50 focus:ring-offset-2'
      )}
      {...props}
    />
  </div>
);

// TODO: Uncomment this when we ship 1.0 release design with two "Try for Free" / "Buy now" buttons.
// const SecondaryButton = ({ className, ...props }) => (
//   <a
//     className={clsx(
//       className,
//       'px-6 py-3 font-display font-bold text-lg no-underline text-gray-700 bg-white border border-black border-opacity-10 rounded-lg shadow-sm transform hover:translate-y-0.5 transition-all appearance-none hover:opacity-90 hover:bg-gray-50 hover:shadow-inner-light active:opacity-100'
//     )}
//     {...props}
//   />
// );

const Input = ({ className, ...props }) => (
  <input
    className={clsx(
      className,
      'px-4 w-80 max-w-full h-12 placeholder-gray-600 text-gray-900 bg-white border border-black border-opacity-10 rounded-lg shadow-sm appearance-none transition-all duration-100 ease-out focus:shadow-none outline-none focus:outline-none focus:ring focus:ring-yellow-500 focus:ring-opacity-50 focus:ring-offset-2'
    )}
    {...props}
  />
);

export default function SignupForm({ className, ...props }) {
  let [email, setEmail] = useState('');

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();

        navigate('/join', {
          state: { email },
        });
      }}
      className={clsx(
        className,
        'max-w-full pt-2 flex flex-col items-center space-y-4 sm:flex-row sm:space-x-6 sm:space-y-0'
      )}
      {...props}
    >
      <Input
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Your e-mail address"
        type="email"
        required
      />

      <PrimaryButton type="submit" onClick={() => trackEvent('open_landing_beta_form')}>
        Download Beta
      </PrimaryButton>
    </form>
  );
}
