import React from 'react';
import { Helmet } from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

// import CookieBox from 'src/components/cookie-box';
import Header from 'src/components/header';
import Footer from 'src/components/footer';
import 'src/layouts/index.scss';

export default function Layout({
  title,
  description,
  children,
  withHeader = true,
  withFooter = true,
}) {
  return (
    <StaticQuery
      query={graphql`
        query LayoutQuery {
          site {
            siteMetadata {
              defaultTitle
              titleTemplate
              description
              siteUrl
            }
          }
        }
      `}
      render={(data) => {
        const pageTitle = renderTitle(
          title,
          data.site.siteMetadata.titleTemplate,
          data.site.siteMetadata.defaultTitle
        );
        const pageDescription = description || data.site.siteMetadata.description;

        return (
          <div>
            <Helmet>
              <html lang="en" />
              <title>{pageTitle}</title>

              <meta name="description" content={pageDescription} />

              <meta property="og:type" content="website" />
              <meta property="og:url" content={data.site.siteMetadata.siteUrl} />
              <meta property="og:title" content={pageTitle} />
              <meta property="og:description" content={pageDescription} />
              <meta
                property="og:image"
                content={`${data.site.siteMetadata.siteUrl}/og_image.png`}
              />
              <meta property="og:image:width" content={1200} />
              <meta property="og:image:height" content={630} />

              <meta name="twitter:card" content="summary_large_image" />
              <meta name="twitter:title" content={pageTitle} />
              <meta name="twitter:description" content={pageDescription} />
              <meta
                name="twitter:image"
                content={`${data.site.siteMetadata.siteUrl}/og_image.png`}
              />

              <link rel="preconnect" href="https://rsms.me" crossOrigin="anonymous" />
              <link rel="preload" as="style" href="https://rsms.me/inter/inter.css?v=3.18" />
              <link rel="stylesheet" href="https://rsms.me/inter/inter.css?v=3.18" />
              <link
                rel="preload"
                href="https://rsms.me/inter/font-files/Inter-roman.var.woff2?v=3.18"
                as="font"
                type="font/woff2"
                crossOrigin="anonymous" // https://developer.mozilla.org/en-US/docs/Web/HTML/Preloading_content#cors-enabled_fetches
              />
              <link
                rel="preload"
                href="https://rsms.me/inter/font-files/Inter-italic.var.woff2?v=3.18"
                as="font"
                type="font/woff2"
                crossOrigin="anonymous" // https://developer.mozilla.org/en-US/docs/Web/HTML/Preloading_content#cors-enabled_fetches
              />
              <link
                rel="preload"
                href="/HKGrotesk-Bold.woff2"
                as="font"
                type="font/woff2"
                crossOrigin="anonymous" // https://developer.mozilla.org/en-US/docs/Web/HTML/Preloading_content#cors-enabled_fetches
              />
            </Helmet>
            {withHeader && <Header />}
            {children}
            {withFooter && <Footer />}
          </div>
        );
      }}
    />
  );
}

function renderTitle(title, titleTemplate, defaultTitle) {
  if (title) {
    return titleTemplate.replace(/%s/g, title);
  } else {
    return defaultTitle;
  }
}
