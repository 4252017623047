import React from 'react';

export const CTA_SECTION = {
  title:
    'Finally focus on\u00A0your\u00A0work.\nBeat\u00A0distractions\u00A0and get\u00A0things\u00A0done.',
  body: (
    <>
      <p>
        See your current task in the always&#8209;visible&nbsp;menu&nbsp;bar&nbsp;item.
        <br />
        Get a&nbsp;reminder to&nbsp;get&nbsp;back&nbsp;on&nbsp;track when&nbsp;distracted.
      </p>
    </>
  ),
};

export const LINK_SECTIONS = [
  {
    title: 'Made by:',
    items: ['bartekjacak', 'neonowy_', 'PanKarol_'].map((twitterHandle) => ({
      title: '@' + twitterHandle,
      url: `https://twitter.com/${twitterHandle}`,
    })),
  },
  // {
  //   title: 'Support:',
  //   items: [
  //     { title: 'Contact Us', url: '#' },
  //     { title: 'Press Kit', url: '#' },
  //   ]
  // }
];
