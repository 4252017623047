import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import clsx from 'clsx';
import trackEvent from 'gatsby-plugin-simple-analytics';

import tailwindConfig from 'src/../tailwind.config.js';
import useViewport from '../../hooks/use-viewport';

import { NAV_BAR_LINKS } from 'src/data/header';

const MEDIUM_BREAKPOINT = parseInt(tailwindConfig.theme.screens.md, 10);

export default function Header() {
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const { width } = useViewport();

  /**
   * Close menu when changing viewport from mobile to desktop.
   * Preserving `isMenuOpened` as `true` on desktop may lead to weird UI bugs.
   */
  useEffect(() => width >= MEDIUM_BREAKPOINT && setIsMenuOpened(false), [width]);

  return (
    <div className={clsx(isMenuOpened && 'pt-15', 'relative flex flex-col items-center')}>
      <nav
        className={clsx(
          isMenuOpened
            ? 'fixed top-0 left-0 z-20 shadow-md bg-nav-bar-open-gradient'
            : 'bg-nav-bar-gradient',
          'flex flex-col px-6 sm:px-10 py-3 w-full max-w-screen-xl transition-all md:flex-row md:items-center md:bg-none'
        )}
      >
        <ul className="flex flex-row justify-between">
          <div className="flex flex-row items-center space-x-2">
            <div className="p-1.5 bg-white rounded-lg shadow-lg md:bg-transparent md:shadow-none">
              <StaticImage
                className="important:block w-6 h-6"
                src="../../assets/logo.png"
                alt="MonoFocus logo"
                quality={80}
                placeholder="none"
              />
            </div>

            <h3>
              <Link
                className="px-1 text-gray-900 no-underline font-semibold rounded focus:outline-none focus:ring-yellow-500 focus:ring-offset-2 focus:ring-opacity-50 focus:ring-2"
                to="/"
              >
                MonoFocus
              </Link>
            </h3>
          </div>

          <button
            className="w-24 text-right font-display font-bold md:hidden"
            onClick={(_) => setIsMenuOpened((state) => !state)}
          >
            {isMenuOpened ? 'Close Menu' : 'Menu'}
          </button>
        </ul>

        <ul
          className={clsx(
            isMenuOpened || 'hidden md:flex',
            'mt-3 px-14 pt-5 pb-7 flex flex-col items-stretch space-y-6 border-t border-black border-opacity-10',
            'md:p-0 md:border-0 md:flex-row md:flex-nowrap md:flex-1 md:space-y-0 md:space-x-8 md:items-center md:justify-end'
          )}
        >
          {NAV_BAR_LINKS.map((props) => (
            <Link
              className="text-gray-900 font-display font-bold no-underline md:text-gray-700 md:hover:text-gray-900 transition-all"
              key={props.to}
              {...props}
            />
          ))}

          <li className="block">
            <div className="shadow-sm overflow-hidden rounded-lg transform transition-all hover:opacity-90 hover:shadow-none active:opacity-100">
              <Link
                className="px-5 py-2.5 w-full h-full block font-display font-bold text-center no-underline text-white bg-yellow-500 shadow-inner-light"
                to="/join"
                onClick={() => trackEvent('open_landing_beta_form')}
              >
                Download Beta
              </Link>
            </div>
          </li>
        </ul>
      </nav>
    </div>
  );
}
