import React from 'react';
import clsx from 'clsx';
import { StaticImage } from 'gatsby-plugin-image';

import { CTA_SECTION, LINK_SECTIONS } from 'src/data/footer';
import SignupForm from 'src/components/signup-form';

const renderSectionItem = ({ title, url }) => (
  <li key={title}>
    <a
      className={clsx(
        'text-gray-800 hover:text-gray-900 rounded no-underline transition-all',
        'focus:outline-none focus:ring-yellow-500 focus:ring-2'
      )}
      href={url}
    >
      {title}
    </a>
  </li>
);

export default function Footer() {
  return (
    <div className="relative flex flex-col items-center mt-16 sm:mt-32 md:mt-48">
      <div className="relative z-10 max-w-full flex flex-col items-center space-y-4 md:flex-row md:items-start md:justify-center pt-20 md:space-x-12">
        <StaticImage
          className="w-20 h-20 md:w-32 md:h-32"
          src="../../assets/logo.png"
          alt="MonoFocus logo"
          quality={80}
          placeholder="blurred"
        />
        <div className="max-w-full flex flex-col items-center text-center space-y-4 md:items-start md:text-left">
          <h1 className="text-gray-900 whitespace-pre-line font-display font-bold text-2xl leading-8 md:text-3xl md:leading-tight">
            {CTA_SECTION.title}
          </h1>

          <div className="text-sm text-gray-800 md:text-base">{CTA_SECTION.body}</div>

          <SignupForm />

          <p className="text-gray-800 text-opacity-80 text-xs md:text-sm">
            Try for free while in beta. macOS 10.15+
          </p>
        </div>
      </div>

      <div className="relative z-10 w-full pb-8 px-4 max-w-screen-xl mt-40">
        <footer className="p-4 border-t border-black border-opacity-10">
          <ul className="flex flex-row space-x-12">
            {LINK_SECTIONS.map((section) => (
              <li key={section.title} className="flex flex-col space-y-3">
                <h4 className="text-gray-800 text-opacity-80">{section.title}</h4>
                <ul className="flex flex-col space-y-2">{section.items.map(renderSectionItem)}</ul>
              </li>
            ))}
          </ul>
          <div className="mt-8 text-gray-800">
            <span className="text-opacity-80">&copy; {new Date().getFullYear()}</span>{' '}
            <span className="font-medium">MonoFocus</span>
          </div>
        </footer>
      </div>

      <div
        className="bg-footer-gradient absolute z-0 bottom-0 left-0 w-full"
        style={{ height: '720px' }}
      />
    </div>
  );
}
