import { useEffect, useState } from 'react';
import { BrowserService } from '../services/browser-service';

export default function useViewport() {
  /**
   * Size is object containing `width` and `height`, which are number values.
   */
  const [size, setSize] = useState(BrowserService.windowSize);

  useEffect(() => {
    const handleWindowResize = () => setSize(BrowserService.windowSize);
    window.addEventListener('resize', handleWindowResize);

    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return size;
}
