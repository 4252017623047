const defaultTheme = require('tailwindcss/defaultTheme');
const plugin = require('tailwindcss/plugin');
const colors = require('tailwindcss/colors');

module.exports = {
  purge: ['./src/**/*.js*'],
  theme: {
    extend: {
      colors: {
        brand: {
          200: '#ffe7b9',
          300: '#ffe0a9',
          400: '#ffbf43',
          500: '#ffaf16',
        },
        yellow: {
          1000: '#201c1a',
        },
      },
      lineHeight: {
        12: '3rem',
        16: '4rem',
        20: '5rem',
        24: '6rem',
        28: '7rem',
        32: '8rem',
      },
      width: {
        9: '36px',
        70: '280px',
      },
      height: {
        9: '36px',
      },
      maxHeight: {
        100: '400px',
      },
      translate: {
        '1/4': '25%',
        1000: '1000px',
        '-1/4': '-25%',
        '-37.5': '-37.5%',
      },
      scale: {
        200: '2',
        25: '.25',
      },
      boxShadow: {
        'inner-light': 'inset 0px 8px 6px rgba(255, 255, 255, 0.2)',
        'dark-icon-circle': 'inset 0px 1px 2px rgba(255, 255, 255, 0.15)',
        highlight: '0px 0px 0px 14px white, 0 0 0px 18px #fde68a, 0 0 6px 18px #ffe0a9',
      },
    },
    colors: {
      transparent: 'transparent',
      current: 'currentColor',
      black: colors.black,
      white: colors.white,
      gray: colors.warmGray,
      yellow: colors.amber,
    },
    screens: {
      smallPhone: { max: '399px' },
      phone: { min: '400px' },
      tabletPortrait: { min: '660px' },
      tabletLandscape: { min: '900px' },
      laptop: { min: '1000px' },
      desktop: { min: '1200px' },
      bigDesktop: { min: '1400px' },
      hugeDesktop: { min: '1600px' },

      sm: '640px',
      // => @media (min-width: 640px) { ... }

      md: '768px',
      // => @media (min-width: 768px) { ... }

      lg: '1024px',
      // => @media (min-width: 1024px) { ... }

      xl: '1280px',
      // => @media (min-width: 1280px) { ... }

      '2xl': '1536px',
      // => @media (min-width: 1536px) { ... }
    },
    spacing: {
      px: '1px',
      0: '0',
      0.5: '2px',
      1: '4px',
      1.5: '6px',
      2: '8px',
      2.5: '10px',
      3: '12px',
      4: '16px',
      5: '20px',
      6: '24px',
      7: '28px',
      8: '32px',
      10: '40px',
      12: '48px',
      14: '56px',
      15: '60px',
      16: '64px',
      20: '80px',
      24: '96px',
      32: '128px',
      40: '160px',
      48: '192px',
      56: '224px',
      64: '256px',
      80: '320px',
    },
    fontFamily: {
      sans: ['"Inter"', ...defaultTheme.fontFamily.sans],
      display: ['"HK Grotesk"', ...defaultTheme.fontFamily.sans],
      mono: ['"Fira Code"', ...defaultTheme.fontFamily.mono],
    },
    fontSize: {
      xs: '0.75rem',
      sm: '0.875rem',
      base: '1rem',
      lg: '1.125rem',
      xl: '1.25rem',
      '2xl': '1.5rem',
      '3xl': '1.875rem',
      '4xl': '2.25rem',
      '5xl': '3rem',
      '6xl': '4rem',
    },
    maxWidth: (theme, { breakpoints }) => ({
      none: 'none',
      xs: '80px',
      sm: '96px',
      md: '112px',
      lg: '128px',
      xl: '144px',
      '2xl': '168px',
      '3xl': '192px',
      '4xl': '224px',
      '5xl': '256px',
      '6xl': '288px',
      '12xl': '480px',
      '13xl': '512px',
      '14xl': '560px',
      full: '100%',
      ...breakpoints(theme('screens')),
    }),
  },
  variants: {
    padding: ['responsive', 'first', 'focus', 'hover'],
    translate: ['responsive', 'hover', 'focus', 'first', 'last'],
    extend: {
      boxShadow: ['target'],
      opacity: ['active'],
      borderWidth: ['focus'],
    },
  },
  plugins: [
    plugin(function ({ addUtilities, theme, e, addVariant }) {
      const translate = theme('translate', {});
      const classPrefix = 'translate-z';
      const utilities = Object.keys(translate)
        .map((key) => [key, translate[key]])
        .map(([key, value]) => {
          var className = `.${classPrefix}-${key}`;
          if (key.startsWith('-')) {
            className = `.-${classPrefix}${key}`;
          }
          return {
            [className.replace('/', '\\/')]: {
              '--tw-translate-z': value,
            },
          };
        });
      addUtilities({
        '.transform': {
          '--tw-translate-z': 0,
          transform:
            'translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) translateZ(var(--tw-translate-z)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))',
        },
      });
      addUtilities(utilities);
      addVariant('target', ({ modifySelectors, separator }) => {
        modifySelectors(({ className }) => {
          return `.${e(`target${separator}${className}`)}:target`;
        });
      });
    }),
  ],
};
