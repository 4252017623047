export const NAV_BAR_LINKS = [
  {
    to: '/#faq',
    children: 'FAQ',
  },
  {
    to: '/features',
    children: 'Features',
  },
];
